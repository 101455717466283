import {termsEn} from './termsEn.js'

export default
{
  "translation": {
    "slogan" : "The # 1 network of aviation contacts!",
    "email" : "Email or Username",
    "password" : "Password",
    "forgot-password": "Forgot password?",
    "btn-join" : "Join Now!",
    "btn-logIn" : "Log in",
    "btn-logInG" : "Sign in with google",
    "btn-logInA" : "Sign in with Apple",

    "news-title" : "Flylinkers is an interactive platform where the aviation community will have the chance to exchange points  of view, participate in webinars, also you will have acces to the latest news, events, seminars and the most important information regarding the aviation's sector",
    "btn-about" : "About us",
    "btn-us" : "About",
    "btn-news" : "News",
    "btn-founders" : "Founding Partners",
    "btn-gallery" : "Hall of Fame",

    "contact" : "Contact Us",

    //LogIn
    "sign-up" : "Sign-up",
    "password-description" : "*Your password must include a minimum of 6 characters and a maximum of 20.",
    "first-name" : "First Name*",
    "middle-name" : "Middle Name",
    "last-name" : "Last Name*",
    "phone" : "Phone",
    "job" : "Title",
    "modal-email" : "Email*",
    "username" : "Username",
    "modal-password" : "Password*",
    "password-confirmation" : "Confirmation*",
    "show-password" : "See password",
    "profile-picture" : "Profile picture (optional)",
    "btn-close-modal" : "Close",
    "btn-create-account" : "CREATE ACCOUNT NOW!",


    //about
    "about-title" : "About FlyLinkers",
    "mision-title" : "Mision",
    "vision-title" : "Vision",
    "about-flylinkers" : "FlyLinkers is an interactive virtual platform that generates a specialized network for executives and professionals of the aeronautical community around the world.",
    "mission" : "FlyLinkers is an interactive virtual platform that generates a specialized network for executives and professionals in the community aeronautics around the world.",
    "vision" : "FlyLinkers will be the leading platform within the community aeronautics that will provide its users with the best connection between them and be able to access relevant aviation information. Its focus is on the progress and prosperity of aviation through the strengthening of relationships between professionals around the world in a friendly, serious and supports progress and innovation in the sector.",

    //Gallery
    "galleryText" : "FlyLinkers members will be able to nominate and elect by public vote at the end of each year a group of members noted for their creative action or significant contribution to the advancement of aviation in that year. The bases for the selection process will be published on the FlyLinkers platform no later than July of each year.",

    //Terms of use
    "terms-of-use": termsEn,
    "terms-modal-title": "FlyLinkers Privacy Policy",
    "terms-modal-error" :"Accept the policy to create your account",
    "btn-terms-continue": "Continue",
    "terms-body" : "I have read and accept the",
    "terms-body2" : "FlyLinkers Privacy Policy"
  }
}