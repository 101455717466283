export const termsEs = `<h2 class="text-center">Política de Privacidad de FlyLinkers</h2>
    <p class="text-end my-3">Fecha efectiva: Octubre 29, 2022.</p>

    <p>
      FlyLinkers opera el sitio web www.FlyLinkers.com (en adelante, el &quot;Servicio&quot;).<br> <br>

      Esta página le informa sobre las políticas con respecto a la recopilación, el uso y la
      divulgación de datos personales, cuando se utiliza el Servicio y las opciones que tiene
      asociadas con esos datos. <br> <br>

      FlyLinkers utiliza sus datos para proporcionar y mejorar el Servicio. Al utilizar el
      Servicio, usted acepta la recopilación y el uso de la información de acuerdo con esta política. A menos que se defina lo contrario en esta política de privacidad, los términos utilizados en esta política de privacidad tienen los mismos significados que en nuestros Términos y condiciones, a los que se puede accede desde <a href="https://www.flylinkers.com/">www.flylinkers.com.</a>
    </p>
      
    <h4>Recopilación y Uso de Información</h4>
    <p>
      FlyLinkers recopila diferentes tipos de información con diversos fines para brindarle y mejorar nuestro Servicio.
    </p>
    
    <h4>Tipo de información recopilada</h4>
    <h6>Información personal</h6>
    <p>Mientras se usa el Servicio, se hace la solicitud de que el usuario proporcione cierta información de 
      identificación personal que pueda usarse para contactarlo o identificarlo 
      (en adelante, "datos personales"). La información de identificación personal puede 
      incluir, pero no se limita a:</p>

    <ul>
      <li>Correo Electrónico</li>
      <li>Nombre y apellido</li>
      <li>Número telefónico</li>
      <li>Dirección, Estado, Provincia, Código postal, Ciudad</li>
      <li>Experiencias laborales, habilidades, intereses</li>
      <li>Cookies y datos de uso</li>
    </ul>

      <h6>Datos de Uso</h6>
      <p>También se recopilará información sobre cómo se accede y se utiliza el Servicio 
        (en adelante, "datos de uso"). Estos datos de uso pueden incluir información como 
        la dirección del Protocolo de Internet de su computadora (por ejemplo, la dirección IP), 
        el tipo de navegador, la versión del navegador, las páginas de nuestro Servicio que 
        visita, la fecha y hora de su visita, el tiempo dedicado a esas páginas, información 
        exclusiva. Identificadores de dispositivos y otros datos de diagnóstico.</p>

      <h6>Seguimiento y datos de cookies</h6>
      <p>
        Se utilizan cookies y tecnologías de seguimiento similares para rastrear la actividad en el Servicio y retener cierta información. <br><br>

        Las cookies son archivos con una pequeña cantidad de datos que pueden incluir un identificador único anónimo. Las cookies se envían a su navegador desde un sitio web y se almacenan en su dispositivo. Las tecnologías de seguimiento también se utilizan como balizas, etiquetas y scripts para recopilar y realizar un seguimiento de la información y para mejorar y analizar nuestro Servicio. <br><br>
        
        Puede indicar a su navegador que rechace todas las cookies o que indique cuándo se está enviando una cookie. Sin embargo, si no acepta las cookies, es posible que no pueda utilizar algunas partes de nuestro Servicio. <br><br>

        Ejemplo de tipos de Cookies que usamos:
      </p>

      <ul>
        <li> 
          <strong>Cookies de sesión:</strong> 
          Se utilizan cookies de sesión para operar nuestro servicio.
        </li>
        <li> 
          <strong>Cookies de preferencia:</strong> 
          Se utilizan cookies de preferencia para recordar sus preferencias y varias configuraciones.
        </li>
        <li> 
          <strong>Cookies de seguridad:</strong> 
          Se utilizan cookies de seguridad con fines de seguridad.
        </li>
      </ul>

      <h4>Uso de Datos</h4>
      <p>FlyLinkers utiliza los datos recopilados para varios propósitos:</p>

      <ul>
        <li>Proporcionar y mantener el Servicio.</li>
        <li>Para notificarle sobre cambios en nuestro Servicio.</li>
        <li>Para permitirle participar en las funciones interactivas de nuestro Servicio cuando así lo desee.</li>
        <li>Proporcionar atención y soporte al cliente.</li>
        <li>Proporcionar análisis o información valiosa para que podamos mejorar el Servicio.</li>
        <li>Monitorear el uso del Servicio.</li>
        <li>Detectar, prevenir y abordar problemas técnicos.</li>
      </ul>

      <h5>Transferencia de Datos</h5>

      <p>
        Su información, incluidos los datos personales, pueden transferirse a, y mantenerse en, computadoras ubicadas fuera de su estado, provincia, país u otra jurisdicción gubernamental donde las leyes de protección de datos pueden diferir de las de su jurisdicción. <br><br>

        Si se encuentra fuera de Estados Unidos y elige proporcionarnos información, tenga en cuenta que transferimos los datos, incluidos los datos personales, a Estados Unidos y los procesamos allí.<br><br>

        Su consentimiento a esta política de privacidad seguido por el envío de dicha información representa su acuerdo a esa transferencia.<br><br>

        FlyLinkers tomará todos los pasos razonablemente necesarios para garantizar que sus datos se traten de forma segura y de acuerdo con esta política de privacidad y no se transferirán sus Datos personales a una organización o país a menos que existan controles adecuados, incluida la seguridad de su Datos y otra información personal.
      </p>

      <h4>Revelación de Datos</h4>
      <h6>Requisitos legales</h6>
      <p>FlyLinkers puede divulgar sus datos personales con la creencia de buena fe de que dicha acción es necesaria para:</p>

      <ul>
        <li>Cumplir con una obligación legal.</li>
        <li>Para proteger y defender los derechos o propiedad de FlyLinkers.</li>
        <li>Para prevenir o investigar posibles irregularidades en relación con el Servicio.</li>
        <li>Proteger la seguridad personal de los usuarios del Servicio o del público.</li>
        <li>Proteger contra responsabilidad legal.</li>
      </ul>

      <h4>Seguridad de Datos</h4>
      <p>La seguridad de sus datos es importante para FlyLinkers, pero recuerde que ningún método de transmisión a través de Internet o de almacenamiento electrónico es 100% seguro. Si bien la plataforma se esfuerza por utilizar medios comercialmente aceptables para proteger sus datos personales, no se puede garantizar su absoluta seguridad.</p>

      <h4>Proveedores de Servicios</h4>
      <p>
        Es posible que FlyLinkers contrate a terceros e individuos para facilitar nuestro Servicio (en adelante, "Proveedores de Servicios"), para proporcionar el Servicio en nuestro nombre, para realizar servicios relacionados con el Servicio o para ayudarnos a analizar cómo se utiliza nuestro Servicio. <br><br>

        Estos terceros tienen acceso a sus Datos personales solo para realizar estas tareas en nuestro nombre y están obligados a no divulgarlos ni utilizarlos para ningún otro fin.
      </p>

      <h4>Enlaces a Otros Sitios</h4>
      <p>
        El Servicio puede contener enlaces a otros sitios que no son operados por FlyLinkers. Si hace clic en el enlace de un tercero, se lo dirigirá al sitio de ese tercero. Le recomendamos encarecidamente que revise la política de privacidad de cada sitio que visite. <br><br>

        FlyLinkers no tiene control ni asume responsabilidad alguna por el contenido, las políticas de privacidad o las prácticas de los sitios o servicios de terceros.
      </p>

      <h4>Privacidad de Menores de Edad</h4>
      <p>
        El Servicio de FlyLinkers no se dirige a personas menores de 18 años (en adelante, "Niños"). <br><br>

        No se recopila información de identificación personal de ninguna persona menor de 18 años. Si usted es un padre o tutor y sabe que sus hijos han proporcionado datos personales, deberá contactar a <a href="mailto:info@flylinkers.com">info@flylinkers.com.</a> <br><br>

        Si los operadores de FlyLinkers descubren que han recopilado Datos personales de niños sin la verificación del consentimiento de los padres, se tomaran medidas para eliminar esa información los servidores FlyLinkers.
      </p>

      <h4>Cambios en esta Política de Privacidad</h4>
      <p>
        Podemos actualizar la política de privacidad de vez en cuando. y los usuarios serán notificados de cualquier cambio mediante la publicación de la nueva política de privacidad en esta página.<br><br>

        Se le informará por correo electrónico y / o un aviso destacado en el Servicio, antes de que el cambio entre en vigencia y se actualizará la "fecha de vigencia" en la parte superior de esta política de privacidad.<br><br>
        
        Se recomienda revisar esta política de privacidad periódicamente para detectar cualquier cambio. Los cambios a esta Política de privacidad son efectivos cuando se publican en esta página.
      </p>

      <h4>Contenido objetable y usuarios abusivos</h4>
      <p>
        FlyLinkers ofrece a sus usuarios la posibilidad de bloquear a los usuarios abusivos.  Además, dentro de las 24 horas posteriores al informe, se eliminará el contenido, así como el usuario que publicó el contenido objetable.
      </p>

      <h4>Contáctenos</h4>
      <p>
        Si tiene alguna pregunta sobre esta política de privacidad, contáctenos escribiendo a <a href="mailto:info@flylinkers.com">info@flylinkers.com.</a> <br>

        Declaro conocer y aceptar los términos y condiciones antes expuestos.
      </p>`
