import {termsEs} from './termsEs.js'

export default
{
  "translation": {
    "slogan" : "¡La red #1 de contactos de la aviación!",
    "email" : "Correo electrónico o Username",
    "password" : "Contraseña",
    "forgot-password": "¿Has olvidado tu contraseña?",
    "btn-join" : "¡Únete Ahora!",
    "btn-logIn" : "Iniciar sesión",
    "btn-logInG" : "Iniciar sesión con Google",
    "btn-logInA" : "Iniciar sesión con Apple",


    "news-title" : "FlyLinkers es la plataforma interactiva donde la comunidad de la aviacion tendrá la oportunidad de intercambiar opiniones, participar en webinars y además tendrá acceso a las últimas noticias, eventos, seminarios e información relevante del sector",
    "btn-about" : "Sobre nosotros",
    "btn-us" : "Misión - Visión",
    "btn-news" : "Noticias",
    "btn-founders" : "Socios Fundadores",
    "btn-gallery" : "Galería de la fama",

    "contact" : "Contáctenos",

    //LogIn
    "sign-up" : "Registro",
    "password-description" : "*Tu contraseña debe incluir mínimo 6 caracteres y máximo 20.",
    "first-name" : "Primer nombre*",
    "middle-name" : "Segundo nombre",
    "last-name" : "Apellido(s)*",
    "phone" : "Teléfono",
    "job" : "Titulo",
    "modal-email" : "Correo electrónico*",
    "username" : "Nombre de usuario",
    "modal-password" : "Contraseña*",
    "password-confirmation" : "Confirmar contraseña*",
    "show-password" : "Ver contraseña",
    "profile-picture" : "Foto de perfil (opcional)",
    "btn-close-modal" : "Cerrar",
    "btn-create-account" : "CREA TU CUENTA AHORA!",

    //about
    "about-title" : "Acerca de FlyLinkers",
    "mision-title" : "Misión",
    "vision-title" : "Visión",
    "about-flylinkers" : "FlyLinkers es una plataforma virtual interactiva que genera una red especializada para ejecutivos y profesionales de la comunidad aeronáutica de todo el mundo.",
    "mission" : "FlyLinkers es una plataforma virtual interactiva que genera una red especializada para ejecutivos y profesionales de la comunidad aeronáutica de todo el mundo.",
    "vision" : "FlyLinkers será la plataforma líder dentro de la comunidad aeronáutica que brindará a sus usuariosla mejor conexión entre ellos y poder acceder a información relevante de la aviación. Su agenda se centra en el progreso y la prosperidad de la aviación civil a través del fortalecimiento de las relaciones entre profesionales de todo el mundo en un entorno amigable, serio y que apoya el progreso y la innovación en el sector.",

    //Gallery
    "galleryText" : "Los Miembros de FlyLinkers podrán proponer y elegir mediante votación publica a fin de cada año a un grupo de miembros destacados por su acción creativa o de contribución significativa al progreso de la aviación en ese año. Las bases para el proceso de selección serán publicadas en la plataforma de FlyLinkers a más tardar en julio de cada año.",
  
    //Terms of use
    "terms-of-use": termsEs,
    "terms-modal-title": "Política de Privacidad de FlyLinkers",
    "terms-modal-error" :"Acepta la política para crear tu cuenta",
    "btn-terms-continue": "Continuar",
    "terms-body" : "He leído y acepto la",
    "terms-body2" : "política de privacidad de FlyLinkers"

  }
}