export const termsEn = `<h2 class="text-center">FlyLinkers Privacy Policy</h2>
<p class="text-end my-3">Effective Date: October 29, 2022.</p>

<p>
  FlyLinkers operates the website www.FlyLinkers.com (hereinafter, the "Service").<br> <br>

  This page informs you about the policies regarding the collection, use and disclosure of personal data, when the Service is used and the options associated with that data. <br> <br>

  FlyLinkers uses your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this privacy policy, the terms used in this privacy policy have the same meanings as in our Terms and Conditions, which can be accessed from <a href="https://www.flylinkers.com/">www.flylinkers.com.</a>
</p>

<h4>Information Collection and Use</h4>
<p>
  FlyLinkers collects different types of information for various purposes to provide and improve our Service.
</p>

<h4>Types of information collected</h4>
<h6>Personal information</h6>
<p>
  While using the Service, the request is made that the user provide certain personal information that can be used to contact or identify him/her (hereinafter, "personal data"). Personally identifiable information may include, but is not limited to:
</p>

<ul>
  <li>Email</li>
  <li>Name and surname</li>
  <li>Phone number</li>
  <li>Address, State, Province, Zip Code, City</li>
  <li>Work experiences, skills, interests</li>
  <li>Cookies and usage data</li>
</ul>

<h6>Usage Data</h6>
<p>
  Information will also be collected on how the Service is accessed and used (hereinafter, "usage data"). Usage data may include information such as the Internet Protocol address of your computer (for example, the IP address), the type of browser, the browser version, the pages of our Service you visit, the date and time of your visit, the time spent on those pages, exclusive information. Device identifiers and other diagnostic data.
</p>

<h6>Tracking and cookie data</h6>
<p>
  Cookies and similar tracking technologies are used to track activity in the Service and retain certain information. <br><br>

  Cookies are files with a small amount of data that may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies are also used as beacons, labels and scripts to collect and track information and to improve and analyze our Service.<br><br>
  
  You can tell your browser to reject all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some parts of our Service.<br><br>

  Example of types of cookies we use:
</p>

<ul>
  <li> 
    <strong>Session cookies:</strong> 
    Session cookies are used to operate our service.
  </li>
  <li> 
    <strong>Preference cookies:</strong> 
    Preference cookies are used to remember your preferences and various settings.
  </li>
  <li> 
    <strong>Security cookies:</strong> 
    Security cookies are used for security purposes.
  </li>
</ul>

<h4>Data Usage</h4>
<p>FlyLinkers uses the data collected for several purposes:</p>

<ul>
  <li>Provide and maintain the Service.</li>
  <li>To notify you about changes in our Service.</li>
  <li>To allow you to participate in the interactive functions of our Service whenever you wish.</li>
  <li>Provide customer service and support.</li>
  <li>Provide valuable analysis or information so that we can improve the Service.</li>
  <li>Monitor the use of the Service.</li>
  <li>Detect, prevent and address technical problems.</li>
</ul>

<h5>Data transfer </h5>

<p>
  Your information, including personal data, may be transferred to, and maintained on, computers located outside of your state, province, country or other governmental jurisdiction where data protection laws may differ from those in your jurisdiction. <br><br>

  If you are outside the United States and choose to provide us with information, please note that we transfer the data, including personal data, to the United States and process them there.<br><br>

  Your consent to this privacy policy followed by the sending of such information represents your agreement to that transfer.<br><br>

  FlyLinkers will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this privacy policy and your Personal Data will not be transferred to an organization or country unless there are adequate controls, including the security of your Data. and other personal information.
</p>

<h4>Data Disclosure </h4>
<h6>Legal requirements</h6>
<p>FlyLinkers may disclose your personal data with the good faith belief that such action is necessary to: </p>

<ul>
  <li>Comply with a legal obligation.</li>
  <li>Protect and defend the rights or property of FlyLinkers.</li>
  <li>Prevent or investigate possible irregularities in relation to the Service.</li>
  <li>Protect the personal safety of users of the Service or the public.</li>
  <li>Protect against legal liability.</li>
</ul>

<h4>Data Security</h4>
<p>The security of your data is important for FlyLinkers but remember that no method of transmission over the Internet or electronic storage is 100% secure. While the platform strives to use commercially acceptable means to protect your personal data, its absolute security cannot be guaranteed.</p>

<h4>Service providers</h4>
<p>
  It is possible that FlyLinkers hires third parties and individuals to facilitate our Service (hereinafter, "Service Providers"), to provide the Service on our behalf, to perform services related to the Service or to help us analyze how our Service is used. <br><br>

  These third parties have access to your Personal Data only to perform these tasks on our behalf and are obliged not to disclose or use them for any other purpose.
</p>

<h4>Links to Other Sites</h4>
<p>
  The Service may contain links to other sites that are not operated by FlyLinkers. If you click on a third-party link, you will be directed to that third party's site. We strongly recommend that you review the privacy policy of each site you visit. <br><br>

  FlyLinkers has no control or assumes any responsibility for the content, privacy policies or practices of third-party sites or services.
</p>

<h4>Minor Privacy</h4>
<p>
  The FlyLinkers Service is not aimed at people under 18 (hereinafter, "Children"). <br><br>

  No personally identifiable information is collected from any person under the age of 18. If you are a parent or guardian and know that your children have provided personal information, you should contact <a href="mailto:info@flylinkers.com">info@flylinkers.com.</a> <br><br>

  If FlyLinkers operators discover that they have collected Personal Data of children without parental consent verification, steps will be taken to remove that information from FlyLinkers servers.
</p>

<h4>Changes to this Privacy Policy</h4>
<p>
  We may update the privacy policy from time to time. and users will be notified of any changes by posting the new privacy policy on this page.<br><br>

  You will be informed by email and/or a prominent notice on the Service, before the change takes effect and the "effective date" at the top of this privacy policy will be updated.<br><br>
  
  It is recommended to review this privacy policy periodically to detect any changes. Changes to this Privacy Policy are effective when published on this page.
</p>

<h4>Objectionable Content and Abusive Users</h4>
<p>
  FlyLinkers gives its users the possibility of blocking abusive users.  In addition, within 24 hours of the report, the content as well as the user who published the objectionable content, will be deleted.
</p>

<h4>Contact Us</h4>
<p>
  If you have any questions about this privacy policy, contact us by writing to <a href="mailto:info@flylinkers.com">info@flylinkers.com.</a> <br>

  I declare to know and accept the terms and conditions set forth above.
</p>`